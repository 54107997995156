<template>
  <div>
    <div
      v-if="settingsLoaded && !enabled"
      class="alert alert-warning mt-5 fade show"
      v-html="notActivatedText"
    ></div>
  </div>

  <div class="mt-5" v-if="enabled">
    <div class="d-flex justify-content-between mb-4">
      <router-link to="/reports/create" class="btn btn-primary">
        {{ $t("button.createReport") }}
      </router-link>
      <!-- <button class="btn btn-link">
        <i class="far fa-question-circle"></i>
      </button> -->
    </div>

    <div class="mt-3 pb-2 mb-2">
      <strong>{{ $t("page.reportsPage.reportSchedule") }}</strong>
    </div>

    <div class="report-schedule system">
      <div
        v-for="section in sections"
        :key="section.type"
        class="accordion-item border-0"
      >
        <div
          class="d-flex justify-content-between align-items-center py-3 ps-2 pe-3 section-header pointer custom-border-top custom-border-bottom"
          @click="toggleSection(section)"
        >
          <div class="section-title">
            {{ $t(`word.${section.type}`) }}
          </div>
          <div class="d-flex align-items-center section-controls flex-shrink-0">
            <span
              :class="[
                'badge me-4',
                section.enabled ? 'bg-success' : 'bg-secondary',
              ]"
            >
              {{ $t(section.enabled ? "word.enabled" : "word.disabled") }}
            </span>
            <i
              :class="[
                'far',
                section.isOpen ? 'fa-chevron-up' : 'fa-chevron-down',
              ]"
            ></i>
          </div>
        </div>

        <div v-show="section.isOpen" class="p-2">
          <div class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="text-body">{{ $t("word.enabled") }}</div>
              <div class="toggle-btn">
                <div class="button" id="button-13" style="margin: 0">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="section.enabled"
                  />
                  <div class="knobs">
                    <span></span>
                  </div>
                  <div class="layer"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3 flex-nowrap gx-2">
            <div class="col-9">
              <label class="form-label word-break">{{
                $t("page.reportsPage.dataGrouping")
              }}</label>
              <select
                class="form-select shadow"
                v-model="section.periodType"
                :disabled="section.type === 'daily'"
              >
                <option value="D">
                  {{ $t("page.reportsPage.dailyOneRow") }}
                </option>
                <option value="W">
                  {{ $t("page.reportsPage.weeklyOneRow") }}
                </option>
                <option value="M">
                  {{ $t("page.reportsPage.monthlyOneRow") }}
                </option>
              </select>
            </div>
            <div class="col-3">
              <label class="form-label word-break">{{ $t("word.days") }}</label>
              <input
                type="number"
                class="form-control shadow"
                v-model="section.days"
                min="1"
                @input="validateDays($event, section)"
                @blur="validateOnBlur($event, section)"
              />
            </div>
          </div>

          <div class="mb-3" v-show="section.type !== 'yearly'">
            <label class="form-label">{{
              $t("page.reportsPage.activeInterval")
            }}</label>
            <div class="d-flex align-items-center">
              <VueDatePicker
                v-model="section.startDate"
                auto-apply
                disable-year-select
                :format="formatDate"
                :enable-time-picker="false"
                :clearable="false"
                :flow="['month', 'day']"
                :min-date="minDate"
                :max-date="maxDate"
                class="date-picker"
                :locale="locale"
                :disabled="section.type === 'yearly'"
              />
              <span class="mx-2">{{ $t("word.to") }}</span>
              <VueDatePicker
                v-model="section.endDate"
                auto-apply
                disable-year-select
                :format="formatDate"
                :enable-time-picker="false"
                :clearable="false"
                :flow="['month', 'day']"
                :min-date="minDate"
                :max-date="maxDate"
                class="date-picker"
                :locale="locale"
                :disabled="section.type === 'yearly'"
              />
            </div>
          </div>
          <div
            v-show="section.enabled && section.nextReportDate.length > 0"
            class="text-muted"
          >
            {{ $t("page.reportsPage.nextReport") }} {{ section.nextReportDate }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="showSuccess" class="alert alert-success mb-3 fade show">
      {{ $t("page.reportsPage.settingsSaved") }}
    </div>

    <div v-if="errorMessage" class="alert alert-danger mb-3">
      {{ errorMessage }}
    </div>

    <div class="mt-4 mb-3">
      <button
        class="btn btn-primary w-100"
        @click="handleSave"
        :disabled="isLoading"
      >
        <span
          v-if="isLoading"
          class="spinner-border spinner-border-sm me-2"
          role="status"
        ></span>
        {{ isLoading ? $t("button.saving") : $t("button.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import ReportsApi from "../api/reports";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  components: {
    VueDatePicker,
  },

  setup() {
    const store = useStore();
    const errorMessage = ref("");
    const isLoading = ref(false);
    const showSuccess = ref(false);
    const { locale } = useI18n();
    const settingsLoaded = ref(false);
    const enabled = ref(false);
    const notActivatedText = ref("");

    const getCurrentUTCDate = () => {
      const now = new Date();

      return new Date(
        Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
      );
    };

    const currentDate = getCurrentUTCDate();

    const formatDate = (date) => {
      if (!date) return "";

      const utcDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      const day = utcDate.getUTCDate().toString().padStart(2, "0");
      const shortMonthName = new Intl.DateTimeFormat(locale.value, {
        month: "short",
      }).format(utcDate);
      const monthName =
        shortMonthName.charAt(0).toUpperCase() + shortMonthName.slice(1);

      return `${monthName} ${day}`;
    };

    const parseAPIDate = (day, month) => {
      const currentYear = new Date().getFullYear();

      return new Date(Date.UTC(currentYear, month - 1, day));
    };

    const formatYYYYMMDD = (dateString) => {
      if (!dateString) return "";
      return dateString.split("T")[0]; // This will return YYYY-MM-DD part
    };

    const sections = ref([
      {
        type: "daily",
        isOpen: true,
        enabled: true,
        days: 14,
        startDate: currentDate,
        endDate: currentDate,
        id: 1,
        memberId: store.state.authUser.id,
        periodType: "D",
        nextReportDate: "2025-03-04",
      },
      {
        type: "weekly",
        isOpen: false,
        enabled: false,
        days: 7,
        startDate: currentDate,
        endDate: currentDate,
        id: 2,
        memberId: store.state.authUser.id,
        periodType: "D",
        nextReportDate: "2025-03-04",
      },
      {
        type: "monthly",
        isOpen: false,
        enabled: false,
        days: 1,
        startDate: currentDate,
        endDate: currentDate,
        id: 3,
        memberId: store.state.authUser.id,
        periodType: "W",
        nextReportDate: "2025-03-04",
      },
      {
        type: "yearly",
        isOpen: false,
        enabled: false,
        days: 1,
        startDate: currentDate,
        endDate: currentDate,
        id: 4,
        memberId: store.state.authUser.id,
        periodType: "M",
        nextReportDate: "2025-03-04",
      },
    ]);

    onMounted(async () => {
      try {
        const response = await ReportsApi.getReportSettings();
        const { reportPlans, reportsEnabled, reportsNotActivatedText } =
          response.data;
        enabled.value = reportsEnabled;
        notActivatedText.value = reportsNotActivatedText;

        settingsLoaded.value = true;
        mapReportPlans(reportPlans);
      } catch (error) {
        console.error("Failed to load report settings:", error);
      }
    });

    const mapReportPlans = (reportPlans) => {
      sections.value = sections.value.map((section) => {
        const plan = reportPlans.find(
          (p) => p.planType === section.type.charAt(0).toUpperCase()
        );

        if (plan) {
          return {
            ...section,
            id: plan.id,
            enabled: plan.active,
            days: plan.periodLength,
            periodType: plan.periodType,
            nextReportDate: formatYYYYMMDD(plan.nextReportDate),
            startDate: parseAPIDate(
              plan.intervalStartDay,
              plan.intervalStartMonth
            ),
            endDate: parseAPIDate(plan.intervalEndDay, plan.intervalEndMonth),
          };
        }
        return section;
      });
    };

    const adjustDate = (field, section, increment) => {
      const date = new Date(section[field]);
      date.setDate(date.getDate() + increment);
      section[field] = date.toISOString().split("T")[0];
    };

    const toggleSection = (section) => {
      section.isOpen = !section.isOpen;
    };

    const extractErrorMessage = (error) => {
      if (error.response?.data?.errors) {
        const messages = Object.values(error.response.data.errors)
          .flat()
          .filter((msg) => msg);

        return messages.join(", ");
      }
      return "An error occurred while saving the settings";
    };

    const handleSave = async () => {
      isLoading.value = true;
      errorMessage.value = "";
      showSuccess.value = false;

      const formattedData = sections.value.map((section) => {
        const startDate = new Date(
          Date.UTC(
            section.startDate.getFullYear(),
            section.startDate.getMonth(),
            section.startDate.getDate()
          )
        );

        const endDate = new Date(
          Date.UTC(
            section.endDate.getFullYear(),
            section.endDate.getMonth(),
            section.endDate.getDate()
          )
        );

        return {
          id: section.id,
          memberId: section.memberId,
          name: null,
          active: section.enabled,
          periodLength: section.days,
          periodType: section.periodType,
          intervalStartMonth: startDate.getUTCMonth() + 1,
          intervalStartDay: startDate.getUTCDate(),
          intervalEndMonth: endDate.getUTCMonth() + 1,
          intervalEndDay: endDate.getUTCDate(),
          planType: section.type.charAt(0).toUpperCase(),
        };
      });

      try {
        const response = await ReportsApi.updateReportSettings(formattedData);
        mapReportPlans(response.data);

        showSuccess.value = true;

        setTimeout(() => {
          showSuccess.value = false;
        }, 3000);
      } catch (error) {
        console.error("Failed to save settings:", error);
        errorMessage.value = extractErrorMessage(error);
      } finally {
        isLoading.value = false;
      }
    };

    const minDate = new Date(new Date().getFullYear(), 0, 1);
    const maxDate = new Date(new Date().getFullYear(), 11, 31);

    const validateDays = (event, section) => {
      const value = parseInt(event.target.value);
      if (value <= 0) {
        section.days = 1;
      } else {
        section.days = value?.toString()?.trim() || 1;
      }
    };

    const validateOnBlur = (event, section) => {
      const value = event.target.value?.trim();
      if (!value || value === "" || parseInt(value) <= 0) {
        section.days = 1;
      }
    };

    watch(
      sections,
      (newSections) => {
        const dailySection = newSections.find((s) => s.type === "daily");
        if (dailySection && dailySection.periodType !== "D") {
          dailySection.periodType = "D";
        }
      },
      { deep: true }
    );

    return {
      settingsLoaded,
      enabled,
      notActivatedText,
      sections,
      toggleSection,
      adjustDate,
      currentDate,
      formatDate,
      handleSave,
      minDate,
      maxDate,
      errorMessage,
      validateDays,
      validateOnBlur,
      isLoading,
      showSuccess,
      formatYYYYMMDD,
      locale,
    };
  },
};
</script>

<style scoped>
.report-schedule.system {
  padding: 0;

  &::after {
    content: "";
    display: none;
  }
}

.report-schedule > .accordion-item:first-child {
  margin-top: -1px;
}

.report-schedule > .accordion-item {
  margin-bottom: -1px;
}

.form-control,
.form-select {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.form-control:hover,
.form-select:hover {
  border-color: #aaaeb7;
}

.badge {
  padding: 0.5em 0.75em;
}

.section-header {
  min-width: 0;
  background-color: #f5f5f5;
  transition: background-color 0.2s ease-in-out;
  overflow: hidden;
}

.section-header:hover {
  background-color: #e1e1e1;
}

.section-title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  min-width: 0;
  margin-right: 1rem;
  flex: 1;
}

.section-controls {
  white-space: nowrap;
}

.toggle-btn {
  position: relative;
  width: 66px;
  height: 36px;
}

.toggle-btn .button {
  position: relative;
  top: 0;
  width: 66px;
  height: 36px;
}

.form-label {
  margin-bottom: 0.5rem;
  width: 100%;
}

.word-break {
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.custom-border-top {
  border-top: 1px solid #333333;
}

.custom-border-bottom {
  border-bottom: 1px solid #333333;
}

.btn-group-vertical .btn {
  padding: 2px 4px;
  line-height: 1;
}

.btn-group-vertical {
  min-width: 24px;
}

.alert {
  word-break: break-word;
}

select:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
  opacity: 0.8;
}

.fade {
  transition: opacity 0.3s ease-in-out;
}

.fade.show {
  opacity: 1;
}
</style>

<style>
.date-picker.dp__main {
  box-shadow: 0px 2px 5px 1px #dee2e6;
}

.date-picker .dp__input {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 6px 35px 6px 6px;
}

.date-picker .dp__input_icon {
  inset-inline-start: unset;
  inset-inline-end: 0;
}

.alert p:last-child {
  margin-bottom: 0;
}
</style>
