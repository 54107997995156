<template>
  <div class="mt-5">
    <div class="row mb-3 flex-nowrap gx-2">
      <div>
        <label class="form-label word-break">{{
          $t("page.reportsPage.dataGrouping")
        }}</label>
        <select class="form-select shadow" v-model="periodType">
          <option value="D">{{ $t("page.reportsPage.dailyOneRow") }}</option>
          <option value="W">{{ $t("page.reportsPage.weeklyOneRow") }}</option>
          <option value="M">{{ $t("page.reportsPage.monthlyOneRow") }}</option>
        </select>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label">{{
        $t("page.reportsPage.daysToInclude")
      }}</label>
      <div class="d-flex align-items-center">
        <VueDatePicker
          class="date-picker"
          auto-apply
          v-model="startDate"
          :format="formatDate"
          :clearable="false"
          :flow="['year', 'month', 'day']"
          :locale="locale"
          @update:model-value="handleDateSelect"
        />
        <span class="mx-2">{{ $t("word.to") }}</span>
        <VueDatePicker
          class="date-picker"
          auto-apply
          v-model="endDate"
          :format="formatDate"
          :clearable="false"
          :flow="['year', 'month', 'day']"
          :locale="locale"
          @update:model-value="handleDateSelect"
        />
      </div>
    </div>

    <div v-if="showSuccess" class="alert alert-success mb-3 fade show">
      {{ $t("page.reportsPage.reportSentSuccessMessage") }}
    </div>

    <div v-if="errorMessage" class="alert alert-danger mb-3">
      {{ errorMessage }}
    </div>

    <div class="mt-4 mb-3">
      <button
        class="btn btn-primary w-100"
        @click="handleSend"
        :disabled="isLoading"
      >
        {{ isLoading ? $t("button.sending") : $t("button.send") }}
        <span
          v-if="isLoading"
          class="spinner-border spinner-border-sm me-2"
          role="status"
        ></span>
      </button>
    </div>

    <div class="text-muted">
      {{ $t("page.reportsPage.sentReport") }} {{ store.state.authUser.email }}
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ReportsApi from "@/api/reports";
import { useI18n } from "vue-i18n";

export default {
  components: {
    VueDatePicker,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const showSuccess = ref(false);
    const errorMessage = ref("");
    const { locale } = useI18n();

    const getCurrentUTCDate = () => {
      const now = new Date();

      return new Date(
        Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
      );
    };

    const currentDate = getCurrentUTCDate();
    const startDate = ref(currentDate);
    const endDate = ref(currentDate);
    const periodType = ref("D");

    const formatDate = (date) => {
      if (!date) return "";

      const utcDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      const day = utcDate.getUTCDate().toString().padStart(2, "0");
      const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0");
      const year = utcDate.getUTCFullYear();

      return `${year}-${month}-${day}`;
    };

    const handleDateSelect = (date) => {
      if (date) {
        const currentYear = new Date().getUTCFullYear();
        return new Date(Date.UTC(currentYear, date.getMonth(), date.getDate()));
      }
    };

    const handleSend = async () => {
      isLoading.value = true;
      errorMessage.value = "";
      showSuccess.value = false;

      const settings = {
        fromDate: startDate.value.toISOString(),
        toDate: endDate.value.toISOString(),
        periodType: periodType.value,
      };

      try {
        await ReportsApi.sendReport(settings);
        showSuccess.value = true;

        setTimeout(() => {
          showSuccess.value = false;
        }, 10000);
      } catch (error) {
        console.error("Failed to send report:", error);
        errorMessage.value =
          error.response?.data?.message || "Failed to send report";
      } finally {
        isLoading.value = false;
      }
    };

    return {
      startDate,
      endDate,
      periodType,
      formatDate,
      handleDateSelect,
      store,
      handleSend,
      isLoading,
      showSuccess,
      errorMessage,
      locale,
    };
  },
};
</script>

<style scoped>
.alert {
  word-break: break-word;
}

.fade {
  transition: opacity 0.3s ease-in-out;
}

.fade.show {
  opacity: 1;
}
</style>
