import { Modal } from "bootstrap";

export function initializeModal(modalId, modal) {
  try {
    const modalElement = document.getElementById(modalId);

    if (modalElement) {
      modal.value = new Modal(modalElement);
    } else {
      console.warn("Modal element not found in DOM");

      modal.value = {
        show: () => console.log("Modal show called but element doesn't exist"),
        hide: () => console.log("Modal hide called but element doesn't exist"),
      };
    }
  } catch (error) {
    console.error("Failed to initialize Bootstrap modal:", error);

    modal.value = {
      show: () => console.log("Modal show called but initialization failed"),
      hide: () => console.log("Modal hide called but initialization failed"),
    };
  }
}
