import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;

export default {
  getReportSettings() {
    return axios.get(`${apiUrl}/api/member/report-settings?culture=${localStorage.language}`);
  },
  updateReportSettings(settings) {
    return axios.post(`${apiUrl}/api/member/report-settings`, settings);
  },
  sendReport(settings) {
    return axios.post(`${apiUrl}/api/report`, settings);
  },
};
