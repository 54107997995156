import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: "", // set locale
  allowComposition: true,
  fallbackLocale: "en-US", // set fallback locale
  messages: {}, // set locale messages
});

export default i18n;
