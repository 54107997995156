import { createRouter, createWebHistory } from "vue-router";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import System from "./pages/System";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import SystemAlarms from "./pages/SystemAlarms";
import SystemSmsLog from "./pages/SystemSmsLog";
import CompleteProfile from "./pages/CompleteProfile";
import Profile from "./pages/Profile";
import Reports from "./pages/Reports";
import CreateReport from "./pages/CreateReport";
import store from "./store/index";

const routes = [
  { path: "/", name: "/", meta: { middleware: "auth" }, component: Home },
  { path: "/projects", meta: { middleware: "auth" }, component: Projects },
  { path: "/projects/:id", meta: { middleware: "auth" }, component: Project },
  {
    path: "/projects/:projectId/:systemId",
    meta: { middleware: "auth" },
    component: System,
  },
  {
    path: "/projects/:projectId/:systemId/alarms",
    meta: { middleware: "auth" },
    component: SystemAlarms,
  },
  {
    path: "/projects/:projectId/:systemId/smslog",
    meta: { middleware: "auth" },
    component: SystemSmsLog,
  },
  { path: "/profile", meta: { middleware: "auth" }, component: Profile },
  {
    path: "/reports",
    meta: { middleware: "auth", showBackButton: true },
    component: Reports,
  },
  {
    path: "/reports/create",
    meta: { middleware: "auth", showBackButton: true },
    component: CreateReport,
  },
  { path: "/login", component: Login },
  { path: "/reset-password", component: ResetPassword },
  { path: "/reset-password/:token", component: ResetPassword },
  { path: "/complete-profile/:key", component: CompleteProfile },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _, next) => {
  document.getElementById("close-menu").click();

  if (!to.meta.showBackButton) {
    store.commit("setShowBackButton", false);
  } else {
    store.commit("setShowBackButton", true);
  }

  if (to.meta.middleware) {
    const middleware = require(`./middleware/${to.meta.middleware}`);

    if (middleware) {
      middleware.default(next, store);
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
